<template>
    <modal
    ref="modalReclamos"
    :titulo="titulo == 1 ? 'Reclamos por CEDIS' : 'Reclamos del vendedor'"
    :cargado-modal="cargandoIndices"
    icon="Alto"
    :height="60"
    no-aceptar
    no-cancelar
    cerrar
    tamano="modal-lg"
    >
        <div class="row mx-0">
            <div class="col-4 border-right custom-scroll overflow-auto" :style="`height:calc(100vh - ${heightModal}px)`">
                <div v-for="(recla, r) in reclamados" :key="r" class="row mx-0 cr-pointer mb-2 px-2 py-2" :class="reclamoActivo == r ? 'bg-light-f5 br-5' : ''" @click="seleccionarReclamado(recla ,r)">
                    <div class="br-20 col-auto px-1" :class="reclamoActivo == r ? 'bg-general' : ''" />
                    <div class="col pl-2 f-15">
                        <p class="text-general f-500">
                            {{ recla.nombre }}
                        </p>
                        <p class="text-general f-500">
                            {{ agregarSeparadoresNumero(recla.porcentajesReclamos.totalReclamos) }} Reclamos
                        </p>
                        <i v-show="titulo == 2">
                            Cedis: {{ recla.cedis }}
                        </i>
                    </div>
                </div>
            </div>
            <div v-if="reclamoActivo != null" class="col custom-scroll overflow-auto" :style="`height:calc(100vh - ${heightModal}px)`">
                <p class="text-general f-17">
                    {{ fechaString }}
                </p>
                <div class="row mx-0 py-3" />
                <div class="row mx-0 align-items-center">
                    <img :src="reclamado.imagen" width="80" height="80" class="obj-cover br-12" />
                    <div class="col">
                        <p class="text-general f-17 f-500">
                            Reclamos de {{ reclamado.nombre }}
                        </p>
                        <p v-show="titulo == 2" class="text-general f-15">
                            <i>Cedis: {{ reclamado.cedis }}</i>
                        </p>
                    </div>
                </div>
                <div class="row mx-0 py-3" />
                <div class="row mx-0 my-1">
                    <p class="col-auto text-general2 f-15 pl-0">Total pedidos</p>
                    <p class="col-auto text-general2 f-15 ml-auto">{{ agregarSeparadoresNumero(reclamado.porcentajesReclamos.total) }}</p>
                    <div style="width:65px;height:10px;" />
                </div>
                <div class="row mx-0 my-1">
                    <p class="col-auto text-general2 f-15 pl-0">Pedidos con reclamo:</p>
                    <p class="col-auto text-general2 f-15 ml-auto">{{ agregarSeparadoresNumero(reclamado.porcentajesReclamos.totalReclamos) }}</p>
                    <div class="d-flex justify-content-end" style="width:65px;">
                        <div class="bg-general3 f-14 text-white br-20 px-2">
                            {{ reclamado.porcentajesReclamos.porcentaje }} %
                        </div>
                    </div>
                </div>
                <div class="row mx-0 my-1">
                    <p class="col-auto text-general2 f-15 pl-0">Valor de los pedidos:</p>
                    <p class="col-auto text-general2 f-15 ml-auto"> {{ separadorNumero(reclamado.total) }} </p>
                    <div style="width:65px;height:10px;" />
                </div>
                <div class="row mx-0 py-3 mb-4 border-bottom" />
                <div v-for="(data, d) in reclamado.reclamos" :key="d" class="row mx-0 my-1 align-items-center">
                    <div class="rounded-circle" :style="`width:15px;height:15px;background-color:${data.color}`" />
                    <div class="col">
                        {{ data.motivo }}
                    </div>
                    <div class="col-auto">
                        {{ agregarSeparadoresNumero(data.porcentaje_r, 2) }} %
                    </div>
                    <div style="width:65px;height:10px;" />
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Reclamos from '~/services/estadisticas_reclamos'
import {mapGetters} from 'vuex'
import moment from 'moment'
let colorReclamos = [ '#FF9539', '#03D6BC', '#6D3CE1', '#FFDF75', '#284367', '#0892DD', '#A78FE2', '#F1BC8D', '#72DCCF', '#F1E1AB', '#8592A4', '#71C1EC', '#D382C7', '#79BB88',]

export default {
    props: {
        titulo: {
            type: Number,
            default: 1
        }
    },
    data(){
        return {
            reclamoActivo: null,
            reclamado: {
                nombre: null,
                cedis: null,
                imagen: null,
                total: null,
                reclamos: [],
                porcentajesReclamos: {
                    total: 0,
                    totalReclamos: 0,
                    porcentaje: 0,
                },
            },
            cargandoIndices: false,
            fecha: [],
            tipo: null,
            reclamados: [],
            fechaString: '',
        }
    },
    computed:{
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),
        heightModal(){
            let alto = window.innerHeight < 680 ? 284 : 440;
            return alto
        }
    },
    methods: {
        toggle(fecha, tipo){
            this.limpiar()
            this. tipo =  tipo
            this.fecha = fecha
            let fecha_inicio =  moment(fecha[0]).format('Y-MM-DD')
            let fecha_fin =  moment(fecha[1]).format('Y-MM-DD')
            this.fechaString = `${this.formatearFecha(fecha_inicio,'D MMM YYYY')} - ${this.formatearFecha(fecha_fin,'D MMM YYYY')}`
            this.verMasReclamos()
            this.$refs.modalReclamos.toggle();
        },
        async verMasReclamos(){
            try {
                this.cargandoIndices = true
                const params = {
                    fecha_inicio: moment(this.fecha[0]).format('Y-MM-DD'),
                    fecha_fin: moment(this.fecha[1]).format('Y-MM-DD'),
                    id_moneda: this.id_moneda,
                    id_cedis: this.id_cedis,
                    tipo: this.tipo
                }
                const { data } = await Reclamos.verMasReclamos(params)

                data.reclamados.forEach(el => {
                    if (el.reclamos.length > 0){
                        for (let index = 0; index < el.reclamos.length; index++){
                            el.reclamos[index].color = colorReclamos[index]
                        }
                    }
                })

                this.reclamados = data.reclamados
                console.log({recla: this.reclamados});

            } catch (e){
                this.error_catch(e)
            } finally{
                this.cargandoIndices = false
            }
        },
        seleccionarReclamado(dato, index){
            // this.limpiar()
            this.reclamoActivo = index
            this.reclamado = dato
        },
        limpiar(){
            this.reclamoActivo = null
            this.reclamado = {
                nombre: null,
                cedis: null,
                imagen: null,
                total: null,
                reclamos: [],
                porcentajesReclamos: {
                    total: 0,
                    totalReclamos: 0,
                    porcentaje: 0,
                },
            }
            this.reclamados = []
        }

    }
}
</script>

<style>

</style>
